import momentTimezone from "moment-timezone";
import countriesAndTimezones from "countries-and-timezones";
import cityTimezones from "city-timezones";
import { formatStringForComparison } from "@/utils/texts";
import { DEFAULT_USER_LOCALE } from "@/constants/general";

export function setMomentDefaultTimezone(timezone?: string) {
  momentTimezone.tz.setDefault(timezone);
}

interface TimezoneOptions {
  utcOffset: string;
  timezoneName: string;
  timezoneValue: string;

  timezoneCity?: string;
  timezoneCountry?: string;
}
export const timezoneOptions: TimezoneOptions[] = [];
// NOTE: construct timezoneOptions
(() => {
  const countriesAndTimezonesSorted = Object.values(countriesAndTimezones.getAllTimezones()).sort(
    (a, b) => {
      if (a.utcOffset > b.utcOffset) return 1;
      else if (a.utcOffset < b.utcOffset) return -1;
      else return 0;
    }
  );
  const cityTimezonesMapped = cityTimezones.cityMapping;
  countriesAndTimezonesSorted.forEach(countryAndTimezone => {
    const foundCities = cityTimezonesMapped.filter(city => {
      const countryAndTimezoneCity = countryAndTimezone.name.split("/")[1];
      return (
        city.timezone === countryAndTimezone.name &&
        formatStringForComparison(countryAndTimezoneCity) === formatStringForComparison(city.city)
      );
    });

    if (foundCities.length > 0) {
      foundCities.forEach(cityTimezoneMapped => {
        timezoneOptions.push({
          utcOffset: countryAndTimezone.utcOffsetStr,
          timezoneName: getTimezoneName(countryAndTimezone.name),
          timezoneValue: countryAndTimezone.name,

          timezoneCity: cityTimezoneMapped.city,
          timezoneCountry: cityTimezoneMapped.country,
        });
      });
    } else {
      timezoneOptions.push({
        utcOffset: countryAndTimezone.utcOffsetStr,
        timezoneName: getTimezoneName(countryAndTimezone.name),
        timezoneValue: countryAndTimezone.name,
      });
    }
  });
  function getTimezoneName(timezone: string) {
    try {
      return new Intl.DateTimeFormat(DEFAULT_USER_LOCALE, {
        timeZone: timezone,
        timeZoneName: "long",
      })
        .formatToParts()
        .find(part => part.type === "timeZoneName").value;
    } catch (err) {
      console.error(err);
    }

    return "";
  }
})();
