import { jwtDecode } from "jwt-decode";

export function setAuthInfo(accessToken: string, refreshToken?: string) {
  localStorage.setItem("access-token", accessToken);
  if (refreshToken) localStorage.setItem("refresh-token", refreshToken);

  const decodedToken = jwtDecode(accessToken);

  localStorage.setItem("user-id", decodedToken.userId);
  localStorage.setItem("user-email", decodedToken.userEmail);
  localStorage.setItem("company-id", decodedToken.companyId);
}

export function resetAuthInfo() {
  localStorage.removeItem("access-token");
  localStorage.removeItem("refresh-token");

  localStorage.removeItem("user-id");
  localStorage.removeItem("user-email");
  localStorage.removeItem("company-id");
}

export function resetAgendasInfo() {
  localStorage.removeItem("current-agenda-id");
  localStorage.removeItem("agenda-selected-calendar-current-date");
  localStorage.removeItem("agenda-selected-calendar-current-view");
  localStorage.removeItem("agenda-selected-calendar-list-filter");
}
