const SAFE_DARK_COLOR = "#172D41";

const MULTIPLE_SERVICES_EVENT_COLOR = "#D1C5B9";

const DEFAULT_TVA = 1.19;

// ???: ..
const DEFAULT_USER_LOCALE = "ro-RO";
const DEFAULT_COMPANY_TIMEZONE = "Europe/Bucharest";
const UTC_TIMEZONE = "Etc/UTC";

const DATETIME_FORMAT = "YYYY-MM-DDTHH:mm:ssZ";

export {
  SAFE_DARK_COLOR,
  MULTIPLE_SERVICES_EVENT_COLOR,
  DEFAULT_TVA,
  DEFAULT_USER_LOCALE,
  DEFAULT_COMPANY_TIMEZONE,
  UTC_TIMEZONE,
  DATETIME_FORMAT,
};
